.curriculum-wrapper {
  margin-left: 100px;
  padding: 40px 20px 20px 30px;
  background: #f2f7ff;
  overflow-y: auto;
  height: calc(100vh - 60px);
}
.Right-curriculum span {
  font-size: 15px;
  font-weight: bold;
  color: white;
  padding-left: 5px;
}
.Right-curriculum {
  margin-right: 80px;
}
.Right-curriculum button {
  font-size: 12px;
  background-color: #007b85;
  border: 1px solid #007b85;
  color: white;
  font-weight: bold;
  padding: 5px;
  border-radius: 5px;
}
.curriculum-dropdown select {
  border: none;
  width: 80%;
  height: 30px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  color: #08134a;
  padding-left: 10px;
}
.curriculum_Hide {
  visibility: hidden;
}
.Right-curriculum button:hover .curriculum_Hide {
  color: #7a1313;
  visibility: visible;
}
.Curriculum-List ol.Curriculum-Decimal {
  list-style: none;
  padding-left: 0px;
}
.Curriculum-List a {
  text-decoration: none;
  color: #24275e;
}
.Curriculum-List {
  background: #fff;
  width: 85%;
  border-radius: 10px;
}
.Curriculum-List .active {
  background-color: #6ecacd;
  color: #fff;
}
.Curriculum-List li {
  font-size: 12px;
  font-weight: bold;
  border-bottom: 1px solid #08134a1a;
  padding: 5px 0px 5px 15px;
}
.Curriculum-List li::before {
  margin-right: 20px;
  padding-left: 10px;
}
.Curriculum-List li::marker {
  text-align: left;
}
.Curriculum-table {
  width: 680px;
  margin-left: 10px;
}
.Curriculum-table .table > thead {
  background-color: #e9ecff;
}

.Curriculum-table .table > thead th {
  border-style: hidden;
  padding: 15px;
}

.Curriculum-table .table > thead {
  border-top: initial;
  font-size: 13px;
}

.Curriculum-table tbody,
tfoot,
tr,
td {
  border-bottom: 1px solid #08134a1a;
  color: #3e4954;
  background-color: #fff;
  padding: 8px !important;
  border-style: solid;
  border-width: 0;
  vertical-align: middle;
}
.Curriculum-title {
  color: #162880;
  text-decoration: underline;
  font-weight: 500;
  text-align: center;
  font-size: 12px;
}
.Curriculum-right-table {
  width: 75%;
  margin-left: 20%;
  text-align: center;
}
.Curriculum-right-table .table > thead {
  background-color: #e9ecff;
}
.Curriculum-right-table .table > thead th {
  text-align: center;
  padding: 15px;
}

.Curriculum-right-table tbody tr td img {
  width: 25px;
  /* padding:5px 0px 10px 0px !important; */
}

.Curriculum-right-table .table > tbody tr td {
  font-weight: bold;
  text-align: center;
}
.Curriculum-footer {
  font-size: 13px;
  color: #666666;
}
.Curriculum-footer span {
  color: #ff342e;
  padding-right: 10px;
}
