@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
body{
    margin: 0px;
    padding: 0px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    overflow:hidden;
}
.selftest_container
{
  padding-top: 20px;
  background:#F2F7FF;
  overflow-x:hidden;
  overflow-y:auto;
  height: calc(100vh - 0px);
}
.selftest-time button
{
    color:white;
    background-color: #007B85;
    border:1px solid #007B85;
    border-radius:15px;
    padding:2px 8px 2px 8px;
    font-size:14px;
    margin-left: 170px;
    font-weight: bold;
    margin-top: 10px;
}
#pagination{
  display: flex;
  justify-content: center;
}
.blocks{
  width: 35px;
  line-height: 30px;
  text-align: center;
  border: 3px solid #6ECACD;
  display: inline-block;
  text-decoration: none;
  color: #6ECACD;
  border-radius:50%;
  font-weight: bold;
}
.blocks:not(:first-child){
  margin-left: 10px;
}
.blocks:hover{
  background-color: #6ECACD;
  color:white
}
#pagination a.active{
  background-color: #6ECACD;
  color:white
}
.blocks:not(:first-child){
  margin-left: 10px;
}
.blocks:hover{
  background-color: #6ECACD;
  color:white
}
#pagination a.active{
  background-color: #6ECACD;
  color:white
}

.selftest_CloseButton button
{
  color:white;
  background-color: #007B85;
  border:1px solid #007B85;
  border-radius:40px;
  padding:10px 5px;
  font-size:14px;
  width:100px;
  font-weight: bold;
  margin-right: 170px;
  margin-bottom: 30px;
}
.question-card {
  margin: 0 auto;
  width: 80%; 
  height: auto;
  background-color:#FFFFFF;
  padding: 10px;
  text-align: center;
  border-radius: 16px;
  color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;  
}
.question-text
{
  text-align: center;
  font-size:18px;
  color:#020723;
  padding:20px 30px 20px 30px; 
}
#question_description
{
  display:flex;
  justify-content: center;
}
.question-image  img
{
  /* padding-top: 10px; */
  width:480px;
  height:280px;
  border-radius:10px;
  /* padding-top:20px; */
}
.selftest_list_options ul {
  list-style: none;
  margin: 20px 0;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
  align-items: center; 
  justify-content: center;
} 

.selftest_list_options li {
  color: #344356;
  margin:10px 20px 15px 10px;
  background-color:#FFFFFF;
  padding: 10px;
  border: 1px solid white;
  width:380px;
  border-radius: 20px;
  box-shadow:5px 3px 5px 3px  #3C80D116;
  font-size: 15px;
}
.selftest_Submit_Buttons
{
  margin-top:0px;
}
.selftest_Submit_Buttons #next
{
  font-size:14px;
  margin-top:0px;
  width:90px;
  color:#FFFFFF;
  background-color:#007B85;
  border:none;
  border-radius:5px;
}
#Selftest_option_id1
{
  border:2px solid #6ECACD;
  /* color: #31C930; */
}
/* SelfTest1 Component Styles */
#pagination1
{
  display: flex;
  justify-content: center;
}
#pagination1 a.active{
  background-color:#8ED58E;
  color:white;
  border: 3px solid #8ED58E;
}
.blocks1
{
  width: 35px;
  line-height: 30px;
  text-align: center;
  border: 3px solid #6ECACD;
  display: inline-block;
  text-decoration: none;
  color: #6ECACD;
  border-radius:50%;
  font-weight: bold;
}
.blocks1:not(:first-child){
  margin-left: 10px;
}
#Selftest_option_id2 {
  border:2px solid #31C930;
  color: #31C930;
}

.selftest_Submit_Buttons button
{
  margin-left:20px;
  cursor: pointer;
  border:none;
  border-radius:5px;
  font-size:14px;
}
.selftest_Submit_Buttons #ViewSolution
{
  width:160px;
  color:#070606;
  background-color:#EEEEEE;
}
.selftest_Submit_Buttons #Previous
{
  width:90px;
  color:#FFFFFF;
  background-color:#007B85;
  font-size:14px;
  height: 35px;
  margin-top:0px;
  border:none;
  border-radius:5px;
}
.selftest-Tickmark
{
  float: right;
  position:relative;
  right:30px;
  bottom:30px;
  color:#31C930;
  font-weight: bold;
  font-size:14px;
}
.selftest-Tickmark img
{
  width:30px;
}
/* SelfTest2 Component Styles */
.selftest_Submit_Buttons #closesolution
{
  width:160px;
  color:#FFFFFF;
  background-color:#007B85;
  border:none;
  font-size:14px;
  border-radius:5px;
}
/*ViewSolution Component Styles */
.selftest-Lesson-left h5
{
  text-align:left;
  margin:20px;
  padding-left:20px;
  font-weight: bold;
  color:black;
}
.selftest-Lesson-right h5
{
  text-align:left;
  margin:20px;
  padding-left:20px;
  font-weight: bold;
  color:black;
}
.selftest-Lesson-Paragraph-left 
{
  background-color:#F2F7FF;
  text-align: justify;
  margin:30px;
  height: 580px;
  padding:20px;
}
.selftest-Lesson-Paragraph-left  p
{
  font-size:12px;
  color:#666666;
  font-weight: bold;
  margin: 30px;
}
.selftest-Lesson-Paragraph-right  p
{
  font-size:12px;
  color:#666666;
  font-weight: bold;
  margin: 30px;
  padding-top: 35px;
}
.selftest-Lesson-Paragraph-right 
{
  background-color:#F2F7FF;
  text-align: justify;
  margin:30px;
  padding:20px;
  height: 580px;
}
.selftest-Lesson-Paragraph-right img
{
  width:380px;
  height:190px;
  margin-top: 35px;
  margin-left: 25px;
  border:1px solid #707070;
}
.selftest-close-button #closesolution
{
  width:160px;
  color:#FFFFFF;
  background-color:#007B85;
  border:none;
  border-radius:5px;
}
/* Selftest Question Page Styles */
.selftest_Question_Buttons button
{
  margin:20px 35px 10px 35px;
  width:330px;
  height:35px;
  color:#344356;
  font-size:14px;
  opacity: 80%;
  border-radius:20px;
  text-align:left;
  border:none;
  background: #FFFFFF;
  box-shadow: 4px 4px 4px 4px #F2F7FF;
}
.selftest_question_tags
{
  font-size:19px;
  font-weight:bold;
  padding:5% 15% 5% 20%;
  text-align: center;
}
.selftest_question_tags h5
{
    font-size:19px;
    text-align: justify;
    font-weight:bold;
    padding-bottom: 10px;
} 
.selftest_Question_Buttons #button1 
{
  border:2px solid #6ECACD;
}
.selftest_Question_Buttons #button2
{
  border:2px solid #6ECACD; 
}
.selftest_Question_Submit_Buttons button
{
  margin-top:50px;
  margin-left:80px;
}
.selftest_Question_Submit_Buttons #Previous
{
  width:90px;
  margin-top:30px;
  color:#FFFFFF;
  background-color:#007B85;
  border:none;
  border-radius:5px;
  font-size:14px;
  cursor: pointer;
}
.selftest_Question_Submit_Buttons #buttonE
{
    font-size:14px;
    width:90px;
    margin-top:30px;
    color:#FFFFFF;
    background-color:#007B85;
    border:none;
    border-radius:5px;
}
.selftest_Question_Buttons #button_1
{
  border:2px solid #31C930;
}
.selftest_Question_Buttons #button_2
{
  border:2px solid #31C930;
}
.selftest-Incorrect-Tickmark
{
  float: right;
  position:relative;
  right:30px;
  bottom:30px;
  color:#FF342E;
  font-weight: bold;
  font-size:14px;
}
.selftest-Incorrect-Tickmark img
{
  width:30px;
}
#pagination1 a.deactive{
  background-color:#FF342E;
  color:white;
  border: 3px solid #FF342E;
}
#Selftest_option_id3
{
  border:1px solid #FF342E;
}
.selftest_container-wraper
{
  background-color: white;
  padding-top: 20px;
  /* padding-right: 40px; */
}
.selftest_Submit_message
{
  text-align: center;
  border: 1px solid #F2F7FF;
  width:40%;
  height:350px;
  margin-left:480px;
  background-color:#F2F7FF;
  margin-top:120px;
}
.selftest_Submit_message img
{
  border:3px solid Green;
  border-radius:60%;
  background-color:white;
  padding:20px;
  text-align: center;
  margin-top:60px;
}
.selftest_Submit_message h6
{
  padding-top: 20px;
}
.selftest_Submit_message p
{
  color:#666666;
  padding-top:10px;
}

.select_que {
  background-color: #008533 !important;
  color: white;
}
.curser_event {
  pointer-events: none;
  /* cursor: not-allowed !important; */
  /* background-color: rgb(213, 213, 213) !important; */
}
.cursor_pointer {
  cursor: pointer;
}
.correct_answers{
  background-color:#8ED58E !important;
  color:#FFFFFF;
  border:2px solid #31C930;
}
.wrong_answers{
  background-color:#F08380!important;
  color:#FFFFFF;
  border:2px solid #FD4640;
}
.selftest_list_options .bg_success
{
  border:2px solid #31C930;
}
.selftest_list_options .bg_fail
{
  border:2px solid #FD4640;
}
#question_solution
{
  color:black;
}