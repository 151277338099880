@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
body {
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  overflow-y: hidden !important;
}

/* TopBar Styles */
.Topbar-right {
  margin-left: 40px;
}

.navbar {
  box-shadow: 2px 2px 4px #f5f7ff;
  height: 70px;
  padding-top: 30px;
}

.Searchbar .form-control {
  height: 40px;
  font: normal normal normal 12px/16px Poppins;
  background: #fdfdfd 0% 0% no-repeat padding-box;
  border: 1px solid #e9edf0;
  border-radius: 6px;
  padding-left: 30px;
  margin-top: 20px;
}
.dropdown_data {
  /* background-color: #fdfdfd; */
  width: 95%;
  border: 1px solid gray;
  border-radius: 5px;
  margin: 3%;
  padding: 5px;
}
p.view_p:nth-child(odd) {
  background: white;
}

p.view_p:nth-child(even) {
  background: #e9fff9;
  border-radius: 10px;
}
p.view_p {
  padding: 5px;
  margin-bottom: 0px !important;
}

.dropdown_data:empty {
  border: none;
}
.Searchbar .serch-icon {
  display: block;
  color: #6ecacd;
  position: relative;
  top: 30px;
  left: 25px;
}

.ml-auto {
  padding-left: 200px;
}

.Io-Notifications a {
  text-decoration: none;
  color: black;
  font-size: 30px;
  padding: 10px 30px;
}

.Io-Notifications img {
  padding-top: 25px;
  width: 25px;
}

.nav-item.Badges a {
  text-decoration: none;
  color: black;
  font-size: 30px;
}

.Badges {
  padding-left: 20px;
}

.Badges img {
  padding-top: 28px;
  width: 20px;
}

.profile-dropdown li {
  display: inline-block;
}

.profile-dropdown {
  padding-right: 10px;
  padding-left: 60%;
}

.profile-dropdown .top-user li {
  font-size: 15px;
}

.profile-dropdown .top-user a {
  text-decoration: none;
  font-size: 13px;
  color: #000;
}
.myprofile_top_data span {
  position: relative;
  color: #020723;
  opacity: 50%;
  right: 0px;
  bottom: 1px;
  font-size: 8px;
}
.myprofile_top_data p {
  color: #000 !important;
  top: 15px;
  position: relative;
  font-size: 14px;
}

/* .dropdown samp {
  position: relative;
  color: #020723;
  opacity: 60%;
  right: 0px;
  bottom: 10px;
} */

.profile {
  margin-left: 100px;
}

/* .dropdown .nav-link {
  
} */

.nav-link:hover,
.nav-link:focus {
  color: #fff;
  box-shadow: 5px white;
}

.Profile img {
  position: relative;
  width: 50px;
  height: 50px;
  top: 18px;
  left: 30px;
}

.Profile,
.dropdown {
  margin-left: 30px;
}

.dropdown span {
  font-size: 12px;
}

.dropdown:hover .top-user {
  display: block;
  background-color: #ffffff;
  right: 10px;
  left: 10px;
  border: none !important;
  top: 75px;
  box-shadow: 0 10px 10px #fafafa;
}

/* HomeSection Component Styles */
.content-wrapper {
  margin-left: 100px;
  padding: 25px;
  background: #fcfcfc;
  /* border:1px solid #E9EDF0; */
}
.greetings {
  padding-left: 10px;
}
.notice-here {
  background-color: #6ecacd;
  padding: 25px;
  border-radius: 12px;
  color: #fff;
  height: 150px;
  width: 755px;
  margin-left: 10px;
}

.content-wrapper h5 {
  font-size: 15px;
  text-align: left;
}

.notice-here p {
  /* margin-top: 15px; */
  font-size: 12px;
  position: relative;
  text-align: left;
  bottom: 40px;
  padding-right: 400px;
  padding-left: 20px;
}

.notice-here span {
  font-weight: bold;
  font-size: 18px;
  position: relative;
  text-align: left;
  bottom: 23px;
  padding-left: 20px;
}

.notice-here img {
  width: 60px;
}

.notice-here #ellipse {
  width: 100px;
  position: relative;
  right: 260px;
  top: 70px;
}

.notice-here #Vectorbook {
  position: relative;
  width: 10px;
  bottom: 0px;
  top: 25px;
  left: 20px;
}

.notice-here #vector {
  position: relative;
  width: 10px;
  bottom: 0px;
  top: 20px;
  left: 310px;
}

.my-score {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: -32px 0px 64px #0a0a1f05;
  border-radius: 10px;
  margin-top: 10px;
  padding: 0px 0px 30px 10px;
  margin-right: 100px;
  opacity: 1;
  margin-left: 10px;
  width:755px;
}
.my-score h4 {
  padding-top: 20px;
  padding-left: 10px;
  /* font-size: 18px; */
  font: normal normal 600 18px/30px Poppins;
}

.my-score span {
  float: right;
  position: relative;
  bottom: 40px;
  left: -20px;
  font: normal normal 300 14px/25px Poppins;
}

.title a {
  text-decoration: none;
  color: black;
}

.math-card {
  text-align: center;
  font: normal normal normal 11px/18px Poppins;
  letter-spacing: 0px;
  color: #666666;
  border: 4px solid #f98965;
  border-radius: 4px;
  padding: 20px 30px 20px 30px;
}

.math-card .count {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 3px solid #918e96;
  /* border: 2px solid #918e96; */
  text-align: center;
  margin: 0 auto;
  padding: 14px;
  font: normal normal medium 12px/26px Poppins;
  font-weight: bold;
}
.math-card h6 {
  font: normal normal 600 14px/33px Poppins;
  color: #666666;
}
.math-card p {
  margin: 5px 0;
  font: normal normal normal 8.5px/18px Poppins;
  color: #666666;
}

/* .Physics {
    border: 4px solid #0A91E7;
}

.Biology {
    border: 4px solid #81CCAA;
}

.Chemistry {
    border: 4px solid #FFD952;
} */

/* ViewAll Component Styles  */
.view-all-wraper {
  margin-left: 100px;
  padding: 25px;
  background: #f2f7ff;
  overflow-y: auto;
  height: calc(100vh - 60px);
}

.view-all-wraper h5 {
  padding-left: 20px;
  font-weight: bold;
}

.view-all-left-dta {
  background: #ffffff;
  margin: 20px 30px 20px 5px;
  height: 500px;
}

.view-all-left-dta img {
  width: 250px;
  position: relative;
  left: 35%;
  top: 20%;
}

.view-all-left-dta h6 {
  text-align: center;
  padding-top: 110px;
  color: #111111;
  font-size: 16px;
}

.view-all-left-dta p {
  text-align: center;
  padding-top: 4px;
  color: #777777;
  font-size: 12px;
  position: relative;
  padding-left: 180px;
  padding-right: 150px;
}

.view-all-right-dta {
  background: #ffffff;
  height: 500px;
  margin: 20px 0px 20px 5px;
  padding-top: 50px;
  padding-left: 80px;
  font-size: 12px;
}

.view-all-right-dta select {
  display: block;
  width: 70%;
  height: 40px;
  margin-bottom: 30px;
  background-color: #f2f7ff;
  border: 1px solid #f2f7ff;
  padding-left: 10px;
  padding-right: 10px;
  color: #08134a;
}

.view-all-right-dta .subject-select {
  width: 80%;
  height: 10vh;
  margin-bottom: 8px;
  color: #08134a;
}

.view-all-right-button button {
  background-color: #30d0b6;
  width: 35%;
  margin-top: 80px;
  margin-left: 60px;
  color: #ffffff;
}

.view-all-right-button a {
  text-decoration: none;
  color: #ffffff;
}

/* View All Score Component Styles */
.view-all-left-dta .table > thead th {
  background-color: #00000029;
  padding-left: 30px;
  height: 60px;
  padding-bottom: 20px;
  font-size: 16px;
}

.view-all-left-dta tbody,
tfoot,
tr,
td {
  height: 60px;
}

.view-all-left-dta .table > tbody tr td {
  font-size: 15px;
}

.view-all-left-dta .table > tbody tr td span {
  padding-left: 20px;
}

.view_paragraph {
  padding: 30px 0px 20px 30px;
  font-size: 16px;
  color: #007b85;
  font-weight: bold;
}
.validate-Dropdown {
  text-align: center !important;
  padding: 30px 0px 20px 30px;
  justify-content: space-around;
}
#view_p2 {
  padding-left: auto;
}
.css-1okebmr-indicatorSeparator {
  background-color: none !important;
  width: initial !important;
}
.css-1s2u09g-control {
  background-color: #f2f7ff !important;
}
.css-6j8wv5-Input input[type="text"] {
  color: #08134a !important;
}
.css-14el2xx-placeholder {
  color: #08134a !important;
  font-weight: 600;
}
.css-tj5bde-Svg {
  fill: #000 !important;
}
/* Calender Css */
.calender-wrapper {
  box-shadow: 2px 0px 14px 6px #eeeeee;
  padding: 10px 80px 80px 30px;
  /* margin-left: 0px; */
  margin-top: 33px;
  width: 100%;
  height: 85%;
  /* margin-bottom: 20px; */
}
.calender-details p {
  font: normal normal normal 14px/24px Poppins;
  color: #3a8b8c;
}
.calender-details span {
  font: normal normal 600 14px/24px Poppins;
  opacity: 0.7;
  color: #00aab3;
}
.calender-details h6 {
  font: normal normal normal 14px/24px Poppins;
  letter-spacing: 0px;
  color: #3a8b8c;
  padding-top: 10px;
}
.calender-events h6 {
  font: normal normal normal 14px/24px Poppins;
  letter-spacing: 0px;
  color: #3a8b8c;
  padding-top: 20px;
  padding-bottom: 10px;
}
.calender-events p {
  font: normal normal normal 10px/20px Poppins;
  letter-spacing: 0px;
  color: #918e96;
}
.calender-events span {
  background: #a6c9ca 0% 0% no-repeat padding-box;
  opacity: 1;
  filter: blur(0.4px);
  border-radius: 15px;
  padding: 5px;
  font: normal normal normal 10px/20px Poppins;
  letter-spacing: 0px;
  color: #111111;
}
.react-calendar {
  border: none !important;
  margin-left: 20px;
}
.react-calendar__month-view__weekdays {
  background-color: #eeeeee;
  color: #000000;
}
.react-calendar__viewContainer {
  padding-top: 0%;
}
.react-calendar__navigation {
  background: #e6e6e6 0% 0% no-repeat padding-box;
  position: relative;
  top: 15px;
}
.react-calendar__navigation__label {
  color: #000000;
}
.react-calendar__month-view__days {
  background-color: #f2f2f2;
}
/* .react-calendar__month-view__days:active {
  background-color: #007b85 !important;
}
.react-calendar__tile :active {
  background-color: #007b85 !important;
} */
.react-calendar__tile--active {
  background-color: #6ecacd !important;
}
.react-calendar__month-view__weekdays__weekday abbr[title] {
  text-decoration: none !important;
}
/* .home-section
{
  display:inline-flex;
}
.calender-wrapper
{
 
  display:inline-flex;
} */
