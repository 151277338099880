@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
body{
    margin: 0px;
    padding: 0px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    overflow:hidden;
}
.Help-wraper
{
    margin-left: 100PX;
    padding: 30px;
    background: #F2F7FF;
    overflow-y: auto;
    height: calc(100vh - 60px);
}
.Help-wrapper-content
{
    background-color:#FFFFFF;
    height:700px;
    margin-bottom: 50px;
}
.Help_Left_Content h5
{
    padding: 30px 20px;
    color:#3E4954;
    font-weight: bold;
}
.Help_Left_Content img
{
    padding-left: 20px;
}
.Help_right_Content
{
    width:80%;
    box-shadow:1px 5px 10px 8px  #F2F7FF;
    margin-top: 20px;   
    text-align:justify;
    padding-left: 80px;
    height:650px;
    
}
.Help_right_Content h5
{
    padding-top:40px;   
    color: #3E4954;
    font-weight: bold;
}
.Help_right_Content label
{
    color:#020723;
    font-size: 13px;
    font-weight: bold;
    padding-top:30px;
}
.Help_right_Content ::placeholder{
    font-size: 12px;
    color: #A9A9A9;
}
.Help_right_Content .form-control
{
    background-color: #F2F7FF;
    border: 1px solid #F2F7FF;
}
.Help_right_Content textarea
{
    width:79%;
    height:50%;
    padding-top:10px;
}
.Help_right_Content button
{
    margin-top:30px;
    background-color:#007B85;
    border: 1px solid #007B85;
    color:#FFFFFF;
    font-size:12px;
}
.Help_right_Content a
{
    text-decoration:none;
}
/* Help Message Component Styles */
.Help_card
{
    background: #fff;
    margin: 0 auto;
    z-index: 2;
    width: 600px;
    height: 300px;
    border-radius: 4px;
    padding: 50px;
    position: absolute;
    top: 250px;
    left:33%;
    border:2px solid  #fff;
    text-align: center;
}
.Help_card img
{
    width: 80px;
    height:80px;
    margin-bottom: 20px;
}