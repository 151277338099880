@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  overflow: hidden;
}
.navbar-brand img {
  width: 120px;
  position: relative;
  top: -10px;
}

.navbar-brand {
  text-align: center;
  position: relative;
  top: -10px;
  right: 15px;
  width: 100px;
}

.aside {
  position: absolute;
  top: 0;
  background: #fff;
  /* box-shadow: 18px 4px 70px #00000005; */
  box-shadow: 0px 4px 0px #00000005;
  width: 100px;
  height: 100vh;
}

.sidebar-top nav {
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  position: fixed;
  height: 100vh;
  text-align: center;
  width: 30vh;
}

#home {
  color: #00aab3;
}

#home :hover {
  color: white;
}

#courses #pastpaper #challenge #revision #curriculum #swap #help #faq a {
  color: black;
}

.Sidebar a {
  color: black;
  text-decoration: none;
  position: relative;
  font-size: 18px;
}

.Sidebar a:hover {
  color: white;
  background-color: #007b85;
}

.Sidebar a icon {
  padding: 0.5em;
}

.Leftbar-Icons img {
  width: 15px;
}

.Leftbar-Icons {
  padding-left: 15px;
}

.Sidebar a::after {
  content: attr(data-text);
  position: absolute;
  top: 50%;
  /* transform: translate(0px,0%); */
  white-space: nowrap;
  padding: 10px;
  font-size: 15px;
  background-color: #007b85;
  visibility: hidden;
  /* transition: opacity 0.5s, transform 0.5s; */
  color: white;
  left: 40px;
  z-index: 9;
}

.Sidebar a:hover::after {
  visibility: visible;
  vertical-align: baseline;
  /* transform: scaleY(50px, -50%); */
  color: white;
  transform: translate(20px, -50%);
  opacity: 1;
  /* width: 300px; */
}

.Sidebar-bottom {
  position: relative;
  padding-top: 60px;
}
