@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
body {
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  overflow: hidden;
}
/* login page css */
.main-banner {
  margin: 0 auto;
  width: 85%;
}
.top-p {
  position: relative;
}
.login-banner {
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../public/SqillUPImages/Group 5364.png");
  overflow: hidden;
  width: 85%;
  height: 100%;
  position: absolute;
  top: 85px;
  left: 30px;
}
.Logo img {
  height: 135px;
  width: 135px;
  left: 90px;
  position: absolute;
  top: 25px;
}
@media screen and (max-width: 320px) {
  .login-banner {
    height: 350px;
  }
}

.right-content {
  text-align: left;
  padding-top: 145px;
  width: 350px;
  margin-left: 100px;
  padding-right: 10px;
}
.signin {
  padding-top: 20px;
}
.signin h1 {
  font-size: 1.2rem;
  font-weight: 600;
}
.signin p {
  text-align: left;
  font: normal normal 600 30px/38px Poppins;
  letter-spacing: 0.2px;
  color: #020723;
  opacity: 1;
}
.p2 p {
  text-align: left;
  font: normal normal normal 14px/18px Poppins;
  letter-spacing: 0px;
  color: #020723;
  opacity: 0.87;
  margin-bottom: 5px;
}
.p3 p {
  text-align: left;
  font: normal normal normal 10px/16px Poppins;
  letter-spacing: 0.4px;
  color: #020723;
  opacity: 0.7;
  margin-bottom: 5px;
}
.p4 .Label {
  font: normal normal medium 18px/27px Poppins;
  letter-spacing: 0px;
  color: #020723;
  opacity: 0.87;
  padding-right: 15px;
}
.form-check-label {
  padding-top: 4px;
  padding-left: 8px;
}

.form-check-input {
  border: 3px solid #007b85;
  top: 455px;
  left: 1213px;
  width: 20px;
  height: 20px;
  opacity: 1;
}
.form-check-input:checked {
  background-color: #007b85;
  border-color: #007b85;
}
.form-check-input:focus,
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  box-shadow: none !important;
}
#inlineRadio1:hover {
  background-color: #007b85;
}
#inlineRadio2:hover {
  background-color: #007b85;
}
.Student-Label {
  top: 508px;
  left: 106px;
  width: 131px;
  height: 26px;
  text-align: left;
  font: normal normal normal 14px/28px Poppins;
  letter-spacing: 0.14px;
  color: #020723;
  opacity: 1;
}
.Student-inputfield {
  width: 350px;
  height: 45px;
  background: #f2f7ff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
}
input[type="text"] {
  width: 350px;
  height: 42px;
  background: #f2f7ff 0% 0% no-repeat padding-box;
  border: #f2f7ff;
  border-radius: 8px;
  /* border: 1px solid #F8364F; */
  border: 1px solid #007b85;
  position: relative;
}
input[type="password"] {
  width: 350px;
  height: 42px;
  background: #f2f7ff 0% 0% no-repeat padding-box;
  border: #f2f7ff;
  border-radius: 8px;
  /* border: 1px solid #F8364F; */
  border: 1px solid #007b85;
  position: relative;
}
.invalid-error {
  border: 1px solid #f8364f;
}

.Signin-Button button {
  width: 350px;
  height: 42px;
  background: #6ecacd 0% 0% no-repeat padding-box;
  border-radius: 8px;
  border: #007b85;
  color: #ffffff;
  opacity: 0.87;
}
.form-group {
  margin-bottom: 15px;
  position: relative;
}
.form-group label {
  margin-bottom: 5px;
  font-weight: 400;
}
.form-control:focus {
  border-color: #81d1d3 !important;
  outline: 0;
  box-shadow: 0 0 0 0.12rem rgb(13 110 253 / 25%) !important;
}
.error-msg {
  text-align: left;
  font: normal normal 600 12px/26px Poppins;
  letter-spacing: 0px;
  color: #f82641;
}
.pr-2 {
  padding-right: 6px;
}

.forgot {
  float: right;
  text-align: right;
  font-size: 12px;
  letter-spacing: 0.47px;
  color: #0b92e8;
  text-decoration: none;
  padding-top: 5px;
}

.eye-visible {
  position: absolute;
  top: 40px;
  right: 0px;
  color: #007b85;
}
.eye-visible svg {
  fill: #007b85;
}
.btn-primary:hover {
  color: #fff;
  background-color: #007b85;
  border-color: #007b85;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: #555555;
  width: 100%;
  height: 100vh;
  opacity: 0.5;
  z-index: 1;
}
.forgot-card {
  background: #fff;
  margin: 0 auto;
  width: 450px;
  height: 440px;
  z-index: 2;
  border-radius: 4px;
  padding: 30px;
  position: absolute;
  top: 160px;
  right: 15%;
  border: 2px solid grey;
}
.forgot-card .p-t {
  padding-top: 160px;
  color: #020723;
  font-weight: 600;
  text-align: center;
}
.forgot-card .close {
  padding: 0px 5px;
  float: right;
  font-size: 30px;
  color: #555555;
}
.close a {
  text-decoration: none;
  color: #555555;
}
.close a:hover {
  color: #007b85;
}
/* SignOut Styles */
.signout-card {
  background: #fff;
  margin: 0 auto;
  width: 550px;
  height: 230px;
  z-index: 2;
  border-radius: 10px;
  padding: 30px;
  position: absolute;
  top: 250px;
  left: 30%;
  border: 2px solid #f2f7ff;
}
.signout-card h6 {
  position: relative;
  color: #00aab3;
  font-size: 15px;
  left: 25%;
  bottom: 25px;
}
.signout-card p {
  position: relative;
  color: #707070;
  font-size: 12px;
  left: 25%;
  bottom: 10px;
}
.signout-btn {
  text-align: center;
}
.signout-btn button {
  width: 120px;
  height: 30px;
  border: none;
  color: white;
  border-radius: 5px;
}
.signout-btn .button1 {
  background-color: #30d0b6;
}
.signout-btn .button1:hover {
  background-color: #007b85;
}
.signout-btn .button2 {
  background-color: #999999;
}
/* My Profile Component Styles */

.student-account {
  margin-left: 100px;
  padding: 20px 30px 20px 30px;
  background: #f2f7ff;
  overflow-y: auto;
  height: calc(100vh - 60px);
  border: 1px solid #e9edf0;
}
.student-account h5 {
  font: normal normal 600 18px/46px Poppins;
  letter-spacing: 0px;
  color: #08134a;
}
.Basic {
  background-color: #e9ecff80;
  padding: 10px;
  opacity: 100%;
  border: 1px solid #e9edf0;
}
.Basic span {
  color: #30d0b6;
  opacity: 100%;
}
.Basic h6 {
  font-size: 12px;
  font-weight: 600;
  color: #08134a;
  padding-top: 10px;
}
.form-data label {
  color: #08134a;
  font-size: 12px;
  opacity: 60%;
}

/* .form-data input[placeholder] {
  
  opacity: 100%;
  font-weight: bolder;
} */
.form-data input {
  width: 90%;
  color: #111111;
  font-size: 12px;
  font-weight: bolder;
}
.vertical-line {
  border-right: 2px solid #e9edf0;
}
.user-Image img {
  width: 100px;
}
.form-data .form-group .form-control {
  /* width:initial; */
  height: 35px;
  border: 1px solid #e9edf0;
  border-radius: 5px;
  color: #111111;
}
/* .Student-form
{
    padding:25px;
}
.Student-form .form-control
{
    width:280px;
    height:35px;
    border:1px solid #E9EDF0;
    color:#E9ECFF80;
}
.Student-form label
{
    color:#08134A;
    opacity:60%;
    font-size:10px;
    margin-top: 20px;
}
.Student-form .form-control::placeholder
{
    font-size: 12px;
    font-weight: 600;
    color:black;
} */

.padding-top {
  padding-top: 8rem;
}
/* .student-account input[type='text'] ,.student-account input[type='password']{
    width: initial;
    border: 1px solid #E9EDF0;
    color:#E9ECFF80;
    border-radius:5px;
} */
