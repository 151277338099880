/* Notification Page Styles */
a {
  text-decoration: none;
}
.notifi-content-wrapper {
  margin-left: 100px;
  padding: 25px;
  background: #f2f7ff;
  overflow-y: auto;
  height: calc(100vh - 60px);
}
.notfy-circle {
  width: 40px;
  background-color: #0b92e8;
  height: 40px;
  padding: 5px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
}
.yellow-cicle {
  background-color: #f7c700;
}
.notification {
  width: 100%;
  height: auto;
  background-color: #fff;
  box-shadow: 0px 0px 4px #0000000d;
  border-radius: 0px 0px 6px 6px;
}
.notification__item {
  width: 100%;
  height: auto;
  padding: 12px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.notification__column {
  display: flex;
  align-items: center;
}
.notification__img {
  width: 90px;
  height: auto;
}
.notification__info {
  margin-left: 14px;
  margin-top: 20px;
  width: auto;
}
.notification__company {
  color: #111111;
  font-size: 14px;
  display: block;
  font-weight: 700;
}
.notification__title {
  color: #333;
  font-size: 12px;
  font-weight: 700;
  display: block;
  margin-bottom: 15px;
  width: 270px;
}
.tag:hover,
.tag--active {
  background-color: #5da4a4;
  color: #fff;
}
.tag,
.close-tag {
  display: inline-block;
  width: auto;
  height: auto;
  /* font-weight: 600; */
  font-size: 10px;
  color: #000000;
  cursor: pointer;
  position: relative;
}
.bg-grey {
  background-color: #f4f5ff;
}
.notification-settigs {
  background-color: #fff;
  width: 80%;
}
.notify-card {
  padding: 12px;
}
.notification-settigs h5 {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0.72px;
  color: #000000;
  opacity: 1;
  background-color: #00000029;
  padding: 10px;
}

/* Switch 1 Specific Styles Start */

.box_1 {
  margin: 5px 0;
}

input[type="checkbox"].switch_1 {
  font-size: 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 38px;
  height: 20px;
  background: #ddd;
  border-radius: 3em;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

input[type="checkbox"].switch_1:checked {
  background: #4bbb46;
}

input[type="checkbox"].switch_1:after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  -webkit-box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  left: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

input[type="checkbox"].switch_1:checked:after {
  left: calc(100% - 0.7em);
}
.switch_box label {
  padding-left: 10px;
  position: relative;
  font-size: 13px;
  position: relative;
  top: -8px;
}

/* Switch 1 Specific Style End */
/* Badges Styles */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 0.5;
  z-index: 2;
}
.badges-card {
  background: #091243;
  margin: 0 auto;
  width: 950px;
  height: 500px;
  z-index: 2;
  border-radius: 10px;
  padding: 30px;
  position: absolute;
  top: 85px;
  left: 20%;
  border: 2px solid #091243;
}
/* Left Badges Styles */

.badges-line1 img {
  border: 1px solid transparent;
  width: 90px;
  height: 100px;
  margin: 10px;
  opacity: 100%;
}
/* Badges Line1 Hove Text  */
.badges-line1 {
  box-shadow: o 2px 3px rgb(40, 40, 40);
  transform: translate(0%, 0%);
}
.badges-line1 .overlay {
  position: absolute;
  top: 30px;
  left: 20px;
  right: 0;
  bottom: 50px;
  /* opacity:0;
    transition:opacity 0.4s ease-in-out;
    background-color: rgb(14, 131, 167); */
  width: 73px;
  height: 60px;
  cursor: pointer;
}
.content-badges p {
  position: absolute;
  bottom: 50px;
  color: white;
  transform: translate(0%, 0%);
  opacity: 100%;
}
.content-badges:hover .overlay {
  opacity: 1;
}
/* Badges Line2 styles */
.badges-line2 img {
  width: 90px;
  height: 100px;
  margin: 10px;
  border: 1px solid transparent;
  opacity: 100%;
  padding: 20px;
  border: 1px solid #ff9d00;
}
.badges-line3 img {
  width: 90px;
  height: 100px;
  margin: 10px;
  border: 1px solid transparent;
  opacity: 100%;
  padding: 20px;
  border: 1px solid #ff9d00;
}
.footer-buttons button {
  width: 180px;
  height: 60px;
  text-align: left;
  color: #ffffff;
  margin: 5px;
  border-radius: 2px;
  position: relative;
  top: 25px;
}
.footer-buttons .badges {
  background-color: #ff9d00;
  border: 2px solid #ff9d00;
}
.footer-buttons span {
  color: #ffffff;
  opacity: 50%;
}
.footer-buttons .rewards {
  background-color: #6ecacd;
  border: 2px solid #6ecacd;
}
.horizontal-line hr {
  position: relative;
  margin-top: 5px;
  border-top: 3px solid #ff9d00;
  opacity: 100%;
  bottom: 170px;
}
/* Right Badges Styles */
.right-image img {
  width: 500px;
  height: 400px;
  position: relative;
  right: 55%;
  bottom: 50px;
}
.right-badges p {
  position: relative;
  left: 12%;
  bottom: 180px;
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
}
.badges-paragraph3 hr {
  position: relative;
  right: 20%;
  border-top: 1px solid #eaf0eb;
  bottom: 180px;
  width: 100%;
}
.badges-paragraph1 p {
  position: relative;
  right: 10px;
  color: #ffffff;
}
.badges-paragraph2 p {
  position: relative;
  left: 9px;
  color: #7fc368;
  opacity: 100%;
}
.badges-paragraph3 p {
  position: relative;
  left: 8px;
  color: #ff9d00;
}
.close-button button {
  float: right;
  position: relative;
  bottom: 180px;
  width: 140px;
  height: 40px;
  color: #ffffff;
  background-color: #0b92e8;
}
/* Scrollbar Styles */
.scrollbar {
  margin-left: 30px;
  float: left;
  height: 220px;
  width: 65px;
  overflow-y: scroll;
  margin-bottom: 25px;
  position: absolute;
  bottom: 140px;
  right: 50%;
}

.force-overflow {
  min-height: 750px;
}

#style-7::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  background-color: #87a0af;
  border-radius: 10px;
}

#style-7::-webkit-scrollbar {
  width: 5px;
  background-color: #87a0af;
}

#style-7::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #ffffff;
  /* background-image: -webkit-gradient(linear,
									   left bottom,
									   left top,
									   color-stop(0.44, rgb(122,153,217)),
									   color-stop(0.72, rgb(73,125,189)),
									   color-stop(0.86, rgb(28,58,148))); */
}
/* Reawards Styles */
.right-rewards-image img {
  width: 550px;
  height: 400px;
  position: relative;
  right: 65%;
  bottom: 50px;
}
.right-rewards p {
  position: relative;
  right: 80%;
  bottom: 180px;
  color: #ffffff;
  font-size: 15px;
  font-weight: bold;
}
.rewards-paragraph3 hr {
  position: relative;
  right: 20%;
  border-top: 1px solid #eaf0eb;
  bottom: 180px;
  width: 100%;
}
.rewards-paragraph1 p {
  position: relative;
  left: 40px;
  color: #ffffff;
}
.rewards-paragraph2 p {
  position: relative;
  right: 60px;
  color: #7fc368;
  opacity: 100%;
}
.rewards-paragraph3 p {
  position: relative;
  left: 15px;
  color: #ff9d00;
}
