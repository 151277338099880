.Revision-wrapper {
  margin-left: 100PX;
  padding: 30px 20px 20px 20px;
  background: #F2F7FF;
  overflow-y: auto;
  height: calc(100vh - 60px);
}
.Revision-wrapper h5 {
  font-weight: bold;
  color: #08134A;
  padding-left: initial;
  padding-top: initial;
  font-size: 18px;
}
.Revision-right1 select {
  border: none;
  width: 100%;
  height: 30px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  color: #08134A;
  padding-left: 10px;
}
.Revision-right1 .mar-right {
  margin-left: 56%;
}
.Revision-List ol.Revision-Decimal {
  list-style: none;
  counter-reset: item;
  padding-left: 0px;
  cursor: pointer;
}
.Revision-List {
  background: #fff;
}

/* .Revision-List .Revision-Decimal li {
  background-color: #6ECACD;
  color: #fff;
} */
.Revision-List li {
  font-size: 12px;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  padding: 8px;
  counter-increment: item;
}
.Revision-List li::before {
  content: counter(item);
  margin-right: 20px;
  padding-left: 10px;
}
.Revision-List li::marker {
  text-align: left;
}
/* Revision Table Style Component */
.Revision-table .table>thead {
  background-color: #E9ECFF;
}

.Revision-table .table>thead th {
  border-style: hidden;
  padding:15px;
}

.Revision-table .table>thead {
  border-top: initial;
  font-size: 13px;
}

.Revision-table tbody,
tfoot,
tr,
td {
  border-bottom: 1px solid #08134A1A;
  color: #3E4954;
  background-color: #fff;
  border-style: solid;
  border-width: 0;
  vertical-align: middle;
}

.Revision-title {
  color: #162880;
  text-decoration: underline;
  font-weight: 500;
  text-align: center;
  font-size: 12px;
}

.mini-tab td {
  text-align: center;
}
.mini-tab >thead th{
  padding:15px;
  background-color: #E9ECFF;
}

.revision-buttons {
  font-weight: bold;
  font-size: 12px;
  margin-left: 10px;
  border: 1px solid #EEEEEE;
  background-color: #EEEEEE;
}

#selftest_button {
  background-color: #0B92E8;
  color: #fff;
  padding: 5px 15px;
}


@media (min-width: 1400px) {
  .mar-right {
    margin-left: 77%;
  }
}

.Revision-table-right-data .table>thead th {
  text-align: center;
}

.Revision-table-right-data tbody tr td img {
  width: 20px;
  /* padding:5px 0px 10px 0px !important; */
}

.Revision-table-right-data .table>tbody tr td {
  /* padding: 13px 0px 13px 0px !important; */
  font-weight: bold;
}

.Revision-Icon-Table tbody tr td img {
  width: 15px;
  font-weight: bold;
}

/* Revision_Pastpaper Component Styles */
/* Revision_Pastpaper Buttons Styles */
.revision-pastpaper-buttons {
  margin-left: 30px;
  font-weight: bold;
  font-size: 10px;
}

#selftest1 {
  background-color: #EEEEEE;
  color: #0D0D0D;
  border: 1px solid #EEEEEE;
  padding: 5px 10px;
}

#pastpaper1 {
  background-color: #0B92E8;
  color: #fff;
  padding: 5px 15px;
}

#worksheet {
  background-color: #EEEEEE;
  color: #0D0D0D;
  border: 1px solid #EEEEEE;
  padding: 5px 10px;
}

/* Revision PastPaper Table Styles */
.revision-pastpaper-table-data table
{
  width: 850px;
  height:250px;
}
.revision-pastpaper-table-data .table>thead th {
  text-align: left;
  background-color: #E9ECFF;
  padding:15px;
}
.revision-pastpaper-table-data .table>thead th {
 padding-left: 6%;
}
.revision-pastpaper-table-data  span
{
  padding-left:5%;
  font-weight:500;
  color: #24275E;
  font-size:15px;
}
.revision-pastpaer-right-data table
{
  height:300px;
  text-align:center;
}
.revision-pastpaer-right-data .table>tbody tr td img
{
  width:20px;
}
.revision-pastpaer-Icon-Table .table>tbody tr td img
{
  width:15px;
}
.revision-pastpaer-right-data .table>tbody tr td
{
  font-weight: bolder;
  font-size: 15px;
}
/* worksheet Component Styles */
.Revision-worksheet-Icon-Table span
{
  color:#8F9AAA;
}