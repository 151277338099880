/* Challenge Dropdown Styles */
.Challenge-wrapper
{
    margin-left: 100PX;
    padding:30px 50px 60px 50px;
    background: #F2F7FF;
    overflow-y: auto;
    height: calc(100vh - 60px);
  }
  .Challenge-wrapper h5
  {
      font-weight: bold;
  }
  .Challenge-wrapper-Dropdown {
    border: none;
    width: 13%;
    height: 30px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    color: #08134A;
    display: inline-block;
    position: relative;
  }
  .Challenge-wrapper-Dropdown button {
      position: relative;
      right:40px;
      width:200px;
      text-align: left;
      background-color: #fff;
      border: 1px solid #fff;
      padding:10px;
      /* border-bottom:1px solid #00000029; */
      border-radius: 6px;
      font-weight: bold;
  }
  .Challenge-wrapper-Dropdown span 
  {
      padding-left: 90px;
  }
  .Dropdown-data .dropdown-menu
  {
      position: relative;
      right:40px;
      margin-top:1px;
      text-align: left;
      /* border-bottom:1px solid #00000029; */
      border-radius: 6px;
      box-sizing: border-box;
  }
  .Dropdown-data img
  {
    position: absolute;
    width:20px;
  }
  .Dropdown-data p
  {
      font-size:12px;
      padding-left: 30px;
      
  }
  .Dropdown-data ul li
  {
      border-bottom:1px solid #00000029;
      padding-top: 2px;
  }
  
 .Challenge-wrapper-Dropdown ul.nav li.dropdown:hover ul.dropdown-menu
  { 
    display: block; 
    cursor:pointer;
    width:200px;
    overflow: hidden;
}
/* Challenge Cards Styles ukbc */

.challenge-content-UKBC .card
{
    border-radius: 10px;
    box-shadow:2px;
    margin-right: 10px;
}

.challenge-content-UKBC .card-header
{
    padding:10px 2px 0px 2px;
    background-color:#B6E1CE;
}
.challenge-content-UKBC .card-header p
{   
    font-size: 10px;
    font-weight: bold;
}
.challenge-content-UKBC .card-header  img
{   
   width:60px;
   padding-right: 25px;
}
.challenge-content-UKBC .card-title
{
    padding:10px 20px 0px 15px;
    text-align:justify;
    font-size:12px;
}
.challenge-content-UKBC .card-title p
{
    color:#333333;
}
.challenge-content-UKBC .card-title span
{
    color:#00AAB3;
    text-align:justify;
    padding-left:30px;
    font-size:12px;
}
.challenge-content-UKBC .card-footer
{
    background-color:#B6E1CE;
    height:30px;
}
.challenge-content-UKBC .card-footer a
{
    text-decoration: none;
    color:#050505;
    font-weight:bold;  
}
.challenge-content-UKBC .card-footer p
{
    text-align:center;
    font-weight: bold;
    font-size:12px;
    padding-top:0px 0px 0px 0px;
}
/* Challenge BPHO Styles */
.challenge-content-BPHO .card
{
    border-radius: 10px;
    box-shadow:2px;
    margin-right: 10px;
}

.challenge-content-BPHO .card-header
{
    padding:10px 10px 0px 10px;
    background-color:#0B92E87C;
}
.challenge-content-BPHO .card-header p
{   
    font-size: 10px;
    font-weight: bold;
    padding-top:10px;
}
.challenge-content-BPHO .card-header  img
{   
   width:60px;
   padding-right: 25px;
   padding-top: 8px;
}
.challenge-content-BPHO .card-title
{
    padding:10px 20px 0px 15px;
    text-align:justify;
    font-size:12px;
}
.challenge-content-BPHO .card-title p
{
    color:#333333;
}
.challenge-content-BPHO .card-title span
{
    color:#3A8B8C;
    text-align:justify;
    padding-left:30px;
    font-size:12px;
}
.challenge-content-BPHO .card-footer
{
    background-color:#0B92E87C;
    height:30px;
}
.challenge-content-BPHO .card-footer a
{
    text-decoration: none;
    color:#050505;
    font-weight:bold;  
}
.challenge-content-BPHO .card-footer p
{
    text-align:center;
    font-weight: bold;
    font-size:12px;
    padding-top:0px 0px 0px 0px;
}
/* Challenge RSOC Styles */


.challenge-content-RSOC .card
{
    border-radius: 10px;
    box-shadow:2px;
    margin-right: 10px;
}

.challenge-content-RSOC .card-header
{
    padding:10px 10px 0px 10px;
    background-color:#FFC70080;
}
.challenge-content-RSOC .card-header p
{   
    font-size: 10px;
    font-weight: bold;
    padding-top: 10px;
}
.challenge-content-RSOC .card-header  img
{   
   width:60px;
   padding-right: 25px;
   padding-top: 5px;
}
.challenge-content-RSOC .card-title
{
    padding:10px 20px 0px 15px;
    text-align:justify;
    font-size:12px;
}
.challenge-content-RSOC .card-title p
{
    color:#333333;
}
.challenge-content-RSOC .card-title span
{
    color:#FFC700;
    text-align:justify;
    padding-left:30px;
    font-size:12px;
}
.challenge-content-RSOC .card-footer
{
    background-color:#FFC70080;
    height:30px;
}
.challenge-content-RSOC .card-footer a
{
    text-decoration: none;
    color:#050505;
    font-weight:bold;  
}
.challenge-content-RSOC .card-footer p
{
    text-align:center;
    font-weight: bold;
    font-size:12px;
    padding-top:0px 0px 0px 0px;
}
/* Challenge UKMT STYLES */
.challenge-content-UKMT .card
{
    border-radius: 10px;
    box-shadow:2px;
    margin-right: 10px;
}

.challenge-content-UKMT .card-header
{
    padding:10px 2px 0px 2px;
    background-color:#F6511D8D;
}
.challenge-content-UKMT .card-header p
{   
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    padding-top: 10px;
}
.challenge-content-UKMT .card-header  img
{   
   width:50px;
   padding-right: 25px;
   padding-top: 5px;
  
}
.challenge-content-UKMT .card-title
{
    padding:10px 20px 0px 15px;
    text-align:justify;
    font-size:12px;
}
.challenge-content-UKMT .card-title p
{
    color:#333333;
}
.challenge-content-UKMT .card-title span
{
    color:#F6511D;
    text-align:justify;
    padding-left:30px;
    font-size:12px;
}
.challenge-content-UKMT .card-footer
{
    background-color:#F6511D8D;
    height:30px;
}
.challenge-content-UKMT .card-footer a
{
    text-decoration: none;
    color:#050505;
    font-weight:bold;  
}
.challenge-content-UKMT .card-footer p
{
    text-align:center;
    font-weight: bold;
    font-size:12px;
    padding-top:0px 0px 0px 0px;
}