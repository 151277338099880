.courses-wrapper {
  margin-left: 100px;
  padding: 20px;
  background: #f2f7ff;
  overflow-y: auto;
  /* height: calc(100vh - 60px); */
}
.courses-wrapper h5 {
  font-weight: bold;
  color: #08134a;
  padding-left: 20px;
  padding-top: 20px;
  font-size: 18px;
}
.courses-wrapper .v1 {
  border-left: 2px solid #1111114d;
  margin: 10px;
  opacity: 50%;
}
.courses-right1 select {
  border: none;
  width: 140px;
  height: 30px;
  border-radius: 5px;
  font-size: 12px;
  padding-left: 8px;
  font-weight: bold;
  margin-right: 10px;
  color: #08134a;
}
/* .courses-right1 option
{
  padding: 40px !important;
  margin-top: 50px;
} */
.courses-right2 select {
  border: none;
  width: 140px;
  height: 30px;
  border-radius: 5px;
  padding-left: 8px;
  margin-left: 10px;
  font-size: 12px;
  font-weight: bold;
  color: #08134a;
}
.course-content {
  cursor: pointer;
  flex-wrap: wrap;
}
.course-content .card {
  width: 190px;
  margin-bottom: 40px;
  display: inline-block;
  box-shadow: 0px 3px 6px #00000029;
  margin-left: 15px;
  margin-bottom: 8x;
  height: 230px;
}
.course-content .card-body {
  background-color: #f6c72b;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
}
.card-body p {
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card-body p:hover {
  overflow: visible;
}
.card-title span {
  color: #ffffff;
  font-size: 12px;
  font-weight: bolder;
}
.card-body time {
  font-size: 10px;
  position: relative;
  top: 27px;
  left: 53px;
  color: #ffffff;
  background-color: black;
  border: 3px solid black;
  border-radius: 2px;
}
.course-content img {
  width: 40px;
  height: 40px;
}
.course-content .card-footer {
  background-color: #fff !important;
  border-top: initial !important;
}
.card-footer #p1 {
  text-align: center;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}
/* .card-footer #p2 {
  color: #999999;
  font-size: 12px;
  position: relative;
  bottom: 10px;
  text-align: left;
  padding-left: 10px;
} */
.card-footer #p3 {
  color: #999999;
  font-size: 10px;
  position: relative;
  bottom: 5px;
  text-align: left;
  padding-left: 3px;
}
.card-footer #p4 {
  color: #999999;
  font-size: 10px;
  position: relative;
  bottom: 35px;
  right: 10px;
  float: right;
  text-align: right;
  padding-left: 20px;
}
#p4 li {
  list-style-type: none;
}
.card-footer #description {
  font-size: 12px;
  font-weight: bold;
  /* white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; */
  text-align: center;
  padding-bottom: 10px;
}
.card-footer #description:hover {
  overflow: visible;
  white-space: wrap;
}
.scrollbar-Courses {
  float: left;
  height: 460px;
  width: 80px;
  overflow-y: scroll;
  margin-bottom: 25px;
  position: relative;
  bottom: 500px;
  left: 83%;
}
.force-overflow1 {
  min-height: 1000px;
}
#courses-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #e1e1e1;
}

#courses-scrollbar::-webkit-scrollbar {
  width: 5px;
  background-color: #e1e1e1;
}

#courses-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #f2f7ff;
  /* background-image: -webkit-gradient(linear,
									   left bottom,
									   left top,
									   color-stop(0.44, rgb(122,153,217)),
									   color-stop(0.72, rgb(73,125,189)),
									   color-stop(0.86, rgb(28,58,148))); */
}

/*tabs css */
.tabs {
  position: relative;
}
.tabs input[name="tab-control"] {
  display: none;
}
.tabs .content section h2,
.tabs ul li label {
  font-weight: bold;
  font-size: 18px;
  color: #6ecacd;
}
.tabs ul {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  margin-bottom: 10px;
}

.tabs ul li {
  box-sizing: border-box;
  width: 140px;
  padding: 0 10px;
  text-align: center;
}

.tabs ul li label {
  transition: all 0.3s ease-in-out;
  color: #111;
  padding: 5px auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  cursor: pointer;
  font-weight: 600 !important;
  font-size: 16px;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tabs ul li label br {
  display: none;
}

.tabs ul li label .fas {
  font-size: 22px;
}

.tabs ul li label:hover,
.tabs ul li label:focus,
.tabs ul li label:active {
  outline: 0;
  color: #858585;
}

.tabs .slider {
  position: relative;
  width: 60%;
  transition: all 0.33s cubic-bezier(0.38, 0.8, 0.32, 1.07);
  top: -5px;
}

.tabs .slider .indicator {
  position: relative;
  width: 100%;
  max-width: 18%;
  margin: 0 20px;
  height: 3px;
  background: #6ecacd;
  border-radius: 20px;
}

.tabs .slider1 .indicator {
  position: relative;
  width: 100%;
  max-width: 39%;
  margin: 0 130px;
  background: #40404d;
  opacity: 0.1;
  height: 3px;

  /* background: #00000029; */
  border-radius: 20px;
  bottom: 7.5px;
  /* opacity: 50%; */
}
.border_style {
  border-bottom: 4px solid #6ecacd;
  border-radius: 5px;
}

.tabs .content {
  margin-top: 10px;
  background: #f7f7f7;
}

.tabs .content section {
  display: none;
  -webkit-animation-name: content;
  animation-name: content;
  -webkit-animation-direction: normal;
  animation-direction: normal;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  line-height: 1.4;
}

.tabs .content section h2 {
  color: #428bff;
  display: none;
}

.tabs .content section h2::after {
  content: "";
  position: relative;
  display: block;
  width: 30px;
  height: 3px;
  background: #428bff;
  margin-top: 5px;
  left: 1px;
}

.tabs
  input[name="tab-control"]:nth-of-type(1):checked
  ~ ul
  > li:nth-child(1)
  > label {
  cursor: default;
  color: #007b85;
}

.icon1 {
  color: #f98561;
}

.all-lessons {
  color: #007b85;
}

.icon2 {
  color: #f6c72b;
}

.icon3 {
  color: #ff6243;
}

.icon4 {
  color: #62c909;
}

@media (max-width: 600px) {
  .tabs
    input[name="tab-control"]:nth-of-type(1):checked
    ~ ul
    > li:nth-child(1)
    > label {
    background: rgba(0, 0, 0, 0.08);
  }
}

.tabs input[name="tab-control"]:nth-of-type(1):checked ~ .slider {
  transform: translateX(0%);
}

.tabs
  input[name="tab-control"]:nth-of-type(1):checked
  ~ .content
  > section:nth-child(1) {
  display: block;
}

.tabs
  input[name="tab-control"]:nth-of-type(2):checked
  ~ ul
  > li:nth-child(2)
  > label {
  cursor: default;
  color: #6ecacd;
}

@media (max-width: 600px) {
  .tabs
    input[name="tab-control"]:nth-of-type(2):checked
    ~ ul
    > li:nth-child(2)
    > label {
    background: rgba(0, 0, 0, 0.08);
  }
}

.tabs input[name="tab-control"]:nth-of-type(2):checked ~ .slider {
  transform: translateX(28%);
}

.tabs
  input[name="tab-control"]:nth-of-type(2):checked
  ~ .content
  > section:nth-child(2) {
  display: block;
}

.tabs
  input[name="tab-control"]:nth-of-type(3):checked
  ~ ul
  > li:nth-child(3)
  > label {
  cursor: default;
  color: #6ecacd;
}

@media (max-width: 600px) {
  .tabs
    input[name="tab-control"]:nth-of-type(3):checked
    ~ ul
    > li:nth-child(3)
    > label {
    background: rgba(0, 0, 0, 0.08);
  }
}

.tabs input[name="tab-control"]:nth-of-type(3):checked ~ .slider {
  transform: translateX(50%);
}

.tabs
  input[name="tab-control"]:nth-of-type(3):checked
  ~ .content
  > section:nth-child(3) {
  display: block;
}

.tabs
  input[name="tab-control"]:nth-of-type(4):checked
  ~ ul
  > li:nth-child(4)
  > label {
  cursor: default;
  color: #6ecacd;
}

@media (max-width: 600px) {
  .tabs
    input[name="tab-control"]:nth-of-type(4):checked
    ~ ul
    > li:nth-child(4)
    > label {
    background: rgba(0, 0, 0, 0.08);
  }
}

.tabs input[name="tab-control"]:nth-of-type(4):checked ~ .slider {
  transform: translateX(78%);
}

.tabs
  input[name="tab-control"]:nth-of-type(4):checked
  ~ .content
  > section:nth-child(4) {
  display: block;
}

@-webkit-keyframes content {
  from {
    opacity: 0;
    transform: translateY(5%);
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes content {
  from {
    opacity: 0;
    transform: translateY(5%);
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

@media (max-width: 1000px) {
  .tabs ul li label {
    white-space: initial;
  }

  .tabs ul li label br {
    display: initial;
  }

  .tabs ul li label svg {
    height: 1.5em;
  }
}

@media (max-width: 600px) {
  .tabs ul li label {
    padding: 5px;
    border-radius: 5px;
  }

  .tabs ul li label span {
    display: none;
  }

  .tabs .slider {
    display: none;
  }

  .tabs .content section h2 {
    display: block;
  }
}

/* Ongoing Component Styles */
.slider2 .indicator {
  height: 4px;
  background: #ffc700;
  width: 70%;
  border-radius: 20px;
  position: relative;
  bottom: 2px;
}

/* Completed Component Styles */
.slider3 .indicator {
  height: 4px;
  background: #ffc700;
  width: 100%;
  border-radius: 20px;
  position: relative;
  bottom: 2px;
}

/* Particular Student Component */
.particular-content-wrapper {
  margin-left: 100px;
  padding: 25px;
  background: #f2f7ff;
  overflow-y: auto;
  height: calc(100vh - 60px);
}
.particular-content-align {
  display: flex;
  margin-top: 20px;
  padding: 30px;
}
.particular-content img {
  height: 250px;
  width: 250px;
  position: relative;
  left: 30%;
  top: 10%;
}
/* .player-wrapper .react-player
{
  width:100%;
} */
.particular-content .card-body {
  background-color: #f6c72b;
  height: 400px;
}

.particular-content .card-title {
  text-align: center;
  font-size: 35px;
  font-weight: bolder;
}

.particular-content video {
  position: relative;
  top: 15px;
  height: 60px;
  width: 886px;
  opacity: 40%;
  right: 16px;
}

/* particular-content Right Styles */
.chapters-wise-content {
  background-color: #fff;
  border-radius: 10px;
  margin-left: 40px;
  width: 96%;
}
.chapters-wise-content1 {
  padding: 30px;
}
.chapters-wise-content h5 {
  font-size: 15px;
  color: #344356;
}
.chapters-Iamge img {
  width: 50px;
  height: 50px;
  /* padding: 5px; */
  border-radius: 10%;
}
.chapter-list {
  width: 100%;
  height: auto;
  box-shadow: 0px 14px 40px #f2f7ff;
  border-radius: 5px;
  background-color: #fff;
}

.chapter__item {
  width: 100%;
  height: auto;
  padding: 5px;
  margin: 20px;

  /* box-shadow: 0px 14px 40px #F2F7FF; */
  display: flex;
  justify-content: space-between;
}
.chapter__column {
  display: flex;
  align-items: center;
}
.chapter__column img {
  margin-right: 20px;
}
.chapter__info {
  /* margin-left: 12px; */
  width: auto;
}

.chapter__company {
  font: normal normal 600 12px/22px Poppins;
  /* font-size: 12px; */
  display: block;
  font-weight: 700;
}

.chapter__title {
  font: normal normal 600 12px/22px Poppins;
  font-weight: 700;
  display: block;
  margin-bottom: 13px;
}

.chapter__info .chapter__time {
  font: normal normal normal 10px/19px Poppins;
  font-weight: 700;
  display: block;
  margin-bottom: 10px;
  color: #8f9aaa;
}

.chapter__column .tag img {
  width: 30px;
  color: #5da4a4;
  border-radius: 50%;
}

.chapter__info span {
  color: #000000;
}

/* Particular Subject1 Footer Styles */
.glossary-data {
  margin-left: 0px;
  background-color: #fff;
}
.glossary-data i {
  font-size: 8px;
  /* padding: 5px; */
  padding: 0px 10px 0px 20px;
}

.glossary-data h5 {
  font-size: 15px;
  color: #344356;
}

.glossary-data span {
  font-size: 12px;
  color: #918e96;
  margin: 5px;
}

.glossary-form .form-control {
  background-color: #e9ecff80;
  border: 3px solid #e9edf0;
  opacity: 100%;
  width: 90%;
  margin-left: 7px;
}

.glossary-form .serch-icon {
  display: block;
  position: relative;
  top: 28px;
  left: 20px;
}
.glossary-form input[placeholder] {
  color: #08134a;
  opacity: 50%;
  font-size: 12px;
  padding-left: 30px;
}
.glossary-footer {
  padding-left: 30px;
}
.glossary-footer h5 {
  font-weight: bolder;
  font-size: 18px;
}
.glossary-footer p {
  font-size: 13px;
  padding-left: 2px;
  letter-spacing: 0.14px;
}
/* Tabs Component Styles  */
.tab_container {
  width: 100%;
  padding-top: 40px;
  position: relative;
}
.tab_container input,
section {
  clear: both;
  padding-top: 20px;
  display: none;
}

.tab_container label {
  font-weight: 700;
  font-size: 14px;
  display: block;
  float: left;
  width: 20%;
  /* padding:1.5em; */
  height: 40px;
  padding: 0.5em;
  color: #08134a;
  opacity: 50%;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  background-color: #f2f7ff;
}
/* .Icon_tab{
  font-size: 12px;
} */
.selftest_linktag a {
  text-decoration: none;
  color: #08134a;
}
#tab1:checked ~ #content1,
#tab2:checked ~ #content2,
#tab3:checked ~ #content3,
#tab4:checked ~ #content4,
#tab5:checked ~ #content5 {
  display: block;
  padding: 20px;
  background-color: #ffffff;
  border-bottom: 2px solid #f0f0f0;
}
#content1 h5 {
  font-size: 15px;
  font-weight: bold;
  padding: 15px;
}
#content1 p {
  font-size: 12px;
  /* text-align:left; */
  padding-left: 15px;
}
.tab_container .tab-content p,
.tab_container .tab-content h5 {
  -webkit-animation: fadeInScale 0.7s ease-in-out;
  -moz-animation: fadeInScale 0.7s ease-in-out;
  animation: fadeInScale 0.7s ease-in-out;
}
.tab_container [id^="tab"]:checked + label {
  background-color: #ffffff;
  opacity: 100%;
  box-shadow: 3px solid #e9ecff80;
}
/*Media query*/
/* @media only screen and (max-width: 900px) {
  label span {
    display: none;
  }
  .tab_container {
    width: 98%;
  }
} */
/*Content Animation*/
/* @keyframes fadeInScale {
  0% {
  	transform: scale(0.9);
  	opacity: 0;
  }

  100% {
  	transform: scale(1);
  	opacity: 1;
  }
} */
.tab-Author {
  padding-top: 50px;
  padding-left: 15px;
}
.tab-Author h5 {
  color: #08134a;
  opacity: 50%;
  letter-spacing: 0px;
}
.tab-Author span {
  color: #6ecacd;
  padding-left: 15px;
  font-size: 15px;
  position: relative;
  bottom: 12px;
}
/* PostQuestion Styles  */
.overlay {
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  height: 50vh;
  opacity: 0%;
  z-index: 2;
}
.Post-card {
  background: #f2f7ff;
  margin: 0 auto;
  width: 850px;
  height: 450px;
  z-index: 2;
  padding: 30px;
  position: absolute;
  top: -50%;
  left: 20px;
  border: 2px solid #53b9bc;
  /* position: relative; */
  /* bottom:180px; */
  /* left:20%; */
}
.Post-card-body textarea {
  background-color: #ffffff;
  width: 700px;
  position: relative;
  left: 42px;
  /* top:10px; */
  height: 280px;
  border: 1px solid #eeeeee;
}
.Post-card-body textarea[placeholder] {
  padding: 10px;
  color: #999999;
}
.Post-card h5 {
  font-weight: bold;
  text-align: center;
  font-size: 16px;
}
.Post-card button {
  position: relative;
  top: 30px;
  left: 45%;
  background-color: #007b85;
  color: #fff;
  font-weight: bold;
  border: 2px solid #007b85;
  width: 125px;
  height: 40px;
  border-radius: 5px;
}
.Post-card span {
  position: relative;
  left: 95%;
  bottom: 33px;
  font-size: 18px;
  font-weight: bolder;
}
.Post-card a {
  text-decoration: none;
  color: black;
}
/* Question and Answers Section styles */
.question-answers h5 {
  color: #111111;
  font-size: 12px;
}
.question-answers span {
  color: #53b9bc;
}
.question-answers button {
  color: #53b9bc;
  border: 1px solid #53b9bc;
  background-color: #ffffff;
  font-size: 10px;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.question-answers {
  border-bottom: 1px solid #ebebeb;
  padding: 20px;
}
/* Question and Answers Comments Tab */
.QandA {
  width: 100%;
  height: auto;
}
.QandA__item {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin-left: 14px;
  margin-top: 15px;
  width: auto;
}
.QandA__column {
  display: flex;
  align-items: center;
}
.QandA-Image {
  position: relative;
  bottom: 72px;
  padding-right: 10px;
}
.QandA__info {
  width: auto;
}
.QandA__question {
  color: #111111;
  font-size: 14px;
  display: block;
  padding-bottom: 10px;
  font-weight: 700;
}
.QandA__answer {
  color: #333;
  font-size: 12px;
  display: flex;
  align-content: flex-start;
}
.QandA-uls_lis {
  padding-top: 10px;
}
.QandA-uls_lis ul li {
  list-style-type: disc;
  align-content: flex-start;
  margin-left: 25px;
  font-size: 10px;
}
.QandA-uls_lis p {
  font-size: 10px;
  color: #53b9bc;
  font-weight: bolder;
}
.QandA_tag1 p {
  color: #918e96;
}
.QandA-row {
  border-bottom: 1px solid #ebebeb;
  padding: 20px;
}
.QandA_HideandShow {
  margin: 0px 30px 10px 30px;
  border: 1px solid #ebebeb;
  padding: 15px;
  background-color: #ebebeb;
}
.QandA_HideandShow img {
  position: relative;
  top: 15px;
}
.QandA_comment .QandA__info {
  padding-left: 32px;
}
.QandA-textarea {
  margin: 20px 30px 10px 30px;
}
.Text_area {
  background: rgb(255, 255, 255);
  border-radius: 5px;
  width: 600px;
  margin-left: 15px;
  margin-right: 15px;
  border: 1px solid #53b9bc;
}

.Text_area input {
  background: none;
  border: none;
  font-family: "Segoe UI", sans-serif;
  outline: none;
  font-size: 0.9em;
  box-sizing: border-box;
  width: 100%;
}
/* Chapter-Paper Component Styles  */
.chapterpaper-wrapper {
  text-align: center;
  border: 1px solid red;
  width: 50%;
  margin-left: 350px;
  margin-top: 180px;
  padding: 80px;
}
.chapter_close_icon i a {
  color: #918e96;
}
.chapter_close_icon i {
  font-size: 22px;
  padding-right: 30px;
  position: relative;
  top: -160px;
  float: right;
  cursor: pointer;
}
/* .chapterpaper-wrapper i{
  font-size: 22px;
  position: relative;
  top:-60px;
  float: right;
  left:40px;
  color:red;
} */
.chapterpaper-container img {
  width: 60px;
  padding-bottom: 20px;
}
.chapterpaper-container h6 {
  font-weight: bold;
}
.chapterpaper-container p {
  font-weight: bold;
}
.chapterpaper-container button {
  width: 150px;
  font-size: 12px;
  background-color: #f6511d;
  border: 1px solid #f6511d;
  color: white;
  border-radius: 5px;
}
/*Submit Selftest Page Css*/
.submit_card {
  background: #f2f7ff;
  margin: 0 auto;
  z-index: 2;
  width: 600px;
  height: 300px;
  border-radius: 4px;
  padding: 50px;
  position: absolute;
  top: 200px;
  left: 30%;
  border: 2px solid #fff;
  text-align: center;
  /* box-shadow: 4px 4px 4px #ccc; */
}
.submit_card p {
  color: #666666;
  font-weight: bold;
}
.submit_card h6 {
  color: #111111;
  font-weight: bold;
}
.submit_card img {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
}
.submit_card1 {
  background: #f2f7ff;
  margin: 0 auto;
  z-index: 2;
  width: 600px;
  height: 330px;
  border-radius: 4px;
  padding: 20px 50px 15px 50px;
  position: absolute;
  top: 200px;
  left: 30%;
  border: 2px solid #fff;
  text-align: center;
  /* padding: 50px; */
  /* padding-top: 10px; */
}
.submit_card1 span {
  font-size: 90px;
}
.Star {
  color: #3a8b8c;
}
.half_Star {
  color: #ebebeb;
}
.submit_card1 h6 {
  color: #333333;
  font-weight: bold;
}
.submit_card1 h5 {
  color: #0b0b0b;
}
.submit_card1 h4 span {
  font-size: 25px;
  color: #00aab3;
}
.submit_card1 p {
  color: #333333;
  font-size: 15px;
}
.submit_card2 {
  /* background: #F2F7FF; */
  margin: 0 auto;
  z-index: 2;
  width: 600px;
  height: 330px;
  border-radius: 4px;
  padding: 10px 50px 15px 50px;
  position: absolute;
  top: 140px;
  left: 30%;
  border: 2px solid #fff;
  text-align: center;
}
.submit_card2 img {
  width: 140px;
}
.submit_card2 h4 {
  font-size: 16px;
  padding-top: 10px;
}
.submit_card2 p {
  color: #666666;
  font-size: 13px;
  font-weight: 400;
}
.submit_card2 button {
  width: 120px;
  height: 30px;
  background-color: #ffc700;
  border: 1px solid #ffc700;
  border-radius: 1px;
}
.CircularProgressbar {
  width: 100px !important;
  padding-bottom: 10px;
}
