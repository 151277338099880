/* PastPaperView Component Styles */
.pastpaper-wrapper {
  margin-left: 100px;
  padding: 30px 50px 60px 50px;
  background: #f2f7ff;
  overflow-y: auto;
  height: calc(100vh - 60px);
}
.pastpaper-wrapper h5 {
  font-weight: bold;
  color: #08134a;
  padding-left: initial;
  padding-top: initial;
  font-size: 18px;
}
.pastpaper-right1 select {
  border: none;
  width: 150px;
  height: 30px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  padding-left: 10px;
  color: #08134a;
}
.pastpaper-right2 select {
  border: none;
  width: 100%;
  height: 30px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  padding-left: 10px;
  color: #08134a;
  margin-left: 25px;
}
.year-tag {
  background-color: #fff;
  border-radius: 4px;
  padding: 5px;
  height: 34px;
  margin-top: -30px;
  line-height: 30px;
  font-weight: bold;
}
.past-table .table > thead {
  background: #e9ecff;
}
.past-table .table > thead th {
  padding: 14px;
}
.past-table .table > thead {
  border-top: initial;
}
.past-table tbody,
tfoot,
tr,
td {
  /* padding: 8px !important; */
  border-color: #ccc;
  background-color: #fff;
  border-style: solid;
  border-width: 0;
  /* text-align: justify; */
  vertical-align:middle;
  font: normal normal medium 10px/15px Poppins;
  /* height: 14px; */
}
/* .past-table .table > tbody tr 
{
  padding: 10px !important;
} */
.course-title span {
  color: #162880;
  text-decoration: underline;
  font-weight: 500;
}
.start-test {
  color: #007b85;
  font-weight: 600;
}
.past-table .btn-primary {
  color: #fff;
  background-color: #6ecacd;
  border-color: #6ecacd;
  height: 30px;
  font-size: 12px;
}
.market-top {
  /* padding-top: 43px; */
  margin-top: 43px;
}
/* .market-top thead th tbody tr td
{
  justify-content: center;
  align-content: center;
  vertical-align: middle;
} */
/* .marketing h6{
    background: #E9ECFF;
    padding: 17px;
    text-align: center;
    font-weight: 600;
    font-size: 0.85rem;
    margin-bottom: 0;
}
.list-view ul{
    padding: 0;
}
.list-view ul li{
    border-bottom: 1px solid #e6e4e4;
    list-style: none;
    background: #fff;
    text-align: center;
    padding: 14.5px;
}
.list-view ul li .btn-info {
    color: #fff;
    background-color: #0B92E8 !important;
    border-color: #0B92E8 !important;
    width: 100px;
    height: 30px;
    font-size: 13px;
    font-weight: bold;
} */
/* Switch 1 Specific Styles Start */
.box_2 {
  margin: 5px 0;
  position: relative;
}

input[type="checkbox"].switch_2 {
  font-size: 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 38px;
  height: 20px;
  background: #ddd;
  border-radius: 3em;
  position: absolute;
  right: 1px;
  top: -4px;
  cursor: pointer;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

input[type="checkbox"].switch_2:checked {
  background: #4bbb46;
}

input[type="checkbox"].switch_2:after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  -webkit-box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  left: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

input[type="checkbox"].switch_2:checked:after {
  left: calc(100% - 0.7em);
}
.switch_box2 label {
  padding-left: 10px;
  position: relative;
  font-size: 13px;
  position: relative;
  top: -8px;
}

/* Switch 2 Specific Style End */
.offset-md-10 {
  margin-left: 81.333333%;
}
/* PastPaperView Component Styles */
/* .pastpaperview-wraper
{
  height:70px;
} */
.pastpaperview-wraper {
  margin-top: 100px;
  padding: 0px;
  background-color: #6ecacd;
}
.pastpaperview-wraper h5 {
  padding: 30px 0px 10px 20px;
}
.pastpaperview-wraper img {
  padding: 25px 0px 10px 20px;
  margin-bottom: 10px;
}
.pastpaperview-wraper button {
  margin: 20px;
  padding: 5px 25px 5px 25px;
  text-align: center;
  border-radius: 18px;
  background-color: #ffffff;
  color: #3e4954;
  font-size: 16px;
  border: 1px solid #ffffff;
  font-weight: bold;
}
.pastpaperview-data {
  padding-top: 40px;
  margin-left: 170px;
  margin-right: 200px;
  text-align: justify;
}
.pastpaperview-data p {
  color: #666666;
}
.btn-View {
  background-color: #0b92e8;
  border: 1px solid #0b92e8;
  width: 100px;
  color: #ffffff;
  border-radius: 5px;
}
